import { Avatar, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import {
  CloudDownloadOutlined,
  KeyboardReturnOutlined,
} from "@material-ui/icons";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import React, { useState } from "react";
import Copyright from "./Copyright";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function App() {
  const classes = useStyles();

  const [state, setState] = useState({
    isLoaded: false,
    hideForm: false,
    blockName: "2024-2025",
    blankDCFFilenameUptoV: "blank_dcf_upto_V.pdf",
    blankDCFFilenameUptoVIII: "blank_dcf_upto_VIII.pdf",
    blankDCFFilenameUptoXII: "blank_dcf_upto_XII.pdf",
    blankDCFFilenameUptoXIIVocational: "blank_dcf_upto_XII_vocational.pdf",
  });

  const handleUpdate = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };
  const handleDownloadDCF = (e) => {
    e.preventDefault();
    setState({ ...state, hideForm: true });
    // window.open("/data/" + state.udiseCode + ".pdf");
  };

  if (state.hideForm) {
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          {/* <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar> */}
          <img alt="udise+" src="/udise.jpg" width="200" height="60"></img>
          <Typography component="h1" variant="h5">
            UDISE+ DCF Download
          </Typography>
          <Typography component="h5" variant="h5">
            {state.blockName}
          </Typography>
          <Button
            startIcon={<CloudDownloadOutlined />}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={(e) => window.open("/data/" + state.udiseCode + ".pdf")}
          >
            Download Pre- Filled DCF
          </Button>
          <Button
            startIcon={<KeyboardReturnOutlined />}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={(e) => setState({ ...state, hideForm: false })}
          >
            Back To Home
          </Button>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    );
  } else {
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          {/* <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar> */}
          <img alt="udise+" src="/udise.jpg" width="200" height="60"></img>
          <Typography component="h1" variant="h5">
            UDISE+ DCF Download
          </Typography>
          <Typography component="h5" variant="h5">
            {state.blockName}
          </Typography>

          {/* <a
            href="/UDISE+ Application Guidelines for filling DCF 2020-21.pdf"
            style={{ textAlign: "center" }}
          >
            UDISE+ Guidelines for filling DCF 2020-21
          </a> */}

          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="udiseCode"
              value={state.udiseCode}
              label="UDISE Code"
              type="number"
              id="udiseCode"
              autoComplete="udiseCode"
              onChange={handleUpdate}
            />
            <Button
              startIcon={<CloudDownloadOutlined />}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleDownloadDCF}
            >
              Download UDISE+ DCF
            </Button>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    );
  }
}
